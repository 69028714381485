require('core-js');require('regenerator-runtime/runtime');/* eslint-disable import/no-extraneous-dependencies */
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Foundation
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('@demant/megamenu');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('@demant/burgermenu');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('../../../wdh-feature/wdh-wffm/src/js/webforms-for-marketers-api.js');

// Feature
require('./feature/intro-banner.js');
// require('./feature/form.js');
require('./feature/custom-form.js');
require('./feature/rich-text.js');
require('./feature/text-image-spot.js');
require('./feature/smooth-scroll.js');
require('./feature/image-spot.js');
require('./feature/hearing-test');

// Project
require('./project/main.js');
require('./project/custom-header.js');